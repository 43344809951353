<template>
  <div class="vg_wrapper">
    <el-row>
      <el-col :md="16">
        <el-button size="mini" type="danger" @click="cancel">取消上传</el-button>
        <el-button size="mini" type="primary" @click="confirmIn()">确认上传</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="24">
        <el-table
          max-height="300px"
          ref="multiTable"
          :data="cTableData"
          @selection-change="handleSelectionChange"
          :row-key="getRowKey"
          border
          v-loading="loading"
        >
          <el-table-column type="selection" width="48" align="center" :reserve-selection="true" />
          <el-table-column label="上传时间" prop="create_time" align="center">
            <template v-slot="scope">
              <span v-if="scope.row.create_time">
                {{ scope.row.create_time | formatDate }}
              </span>
              <span v-else class="vg_9f9a9a">暂无</span>
            </template>
          </el-table-column>
          <el-table-column label="上传人" prop="dequ_designer_name" align="center">
            <template v-slot:header="scope">
              <span>上传人</span>
              <el-input v-model="dequ_designer_name" size="mini" placeholder="请输入" />
            </template>
          </el-table-column>
          <el-table-column align="center" label="文件名称" prop="docu_name">
            <template v-slot:header="scope">
              <span>文件名称</span>
              <el-input v-model="docu_name" placeholder="请输入" size="mini" />
            </template>
          </el-table-column>
          <el-table-column label="格式" prop="docu_suffix" align="center"></el-table-column>
          <el-table-column label="稿件等级" prop="dequ_pter_rank" align="center">
            <template v-slot="scope">
              <span v-if="scope.row.dequ_pter_rank">
                {{ scope.row.dequ_pter_rank }}
              </span>
              <span v-else class="vg_9f9a9a">暂无</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="120">
            <template v-slot="scope">
              <el-link v-if="imgarr.indexOf(scope.row.docu_suffix) !== -1" class="vg_cursor" type="primary" @click="clickView(scope.row)">查看</el-link>
              <el-link v-if="'pdf' === scope.row.docu_suffix" class="vg_cursor" type="primary" @click="openPDF(scope.row.docu_url)">查看</el-link>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-dialog title="图片查看" :visible.sync="imageDialogVisible" width="50%" class="showImg" append-to-body>
      <el-row class="vd_dis">
        <el-image class="showImg" :src="showImgUrl" fit="contain"></el-image>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { optnAPI } from '@api/modules/optn';
import { dequAPI } from '@api/modules/dequ';
import { docuAPI } from '@/api/modules/docu';
import helper from '@assets/js/helper.js';
import bus from '@/components/common/bus';

export default {
  name: 'DequImport',
  components: {},
  props: {
    dequId: {
      type: String,
      required: true
    },
    propsForm: {
      type: Object,
      required: true
    }
  },
  computed: {
    cTableData() {
      return this.tableData.filter(
        data =>
          (!this.dequ_designer_name || data.dequ_designer_name.toLowerCase().includes(this.dequ_designer_name.toLowerCase())) &&
          (!this.docu_name || data.docu_name.toLowerCase().includes(this.docu_name.toLowerCase()))
      );
    }
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        dequ_id: null
      },
      totalPage: 0,
      btn: {},
      desiTypeList: [],
      desiRankList: [],
      loading: true,
      multiSelection: [],
      currentPage: 1,
      title: '提示：相同数据仅限导入一条',
      flag1: false,
      flag2: false,
      imgarr: [
        'bmp',
        'jpg',
        'png',
        'tif',
        'gif',
        'pcx',
        'tga',
        'exif',
        'fpx',
        'svg',
        'psd',
        'cdr',
        'pcd',
        'dxf',
        'ufo',
        'eps',
        'ai',
        'raw',
        'WMF',
        'webp',
        'avif',
        'apng',
        'jpeg'
      ],
      showImgUrl: '',
      imageDialogVisible: false,
      dequ_designer_name: '',
      docu_name: ''
    };
  },
  created() {
    this.initData();
  },
  filters: {
    // 时间转换
    formatDate(row) {
      return helper.toTimeDay(row);
    }
  },
  methods: {
    async openPDF(url) {
      window.open(helper.megPath(url));
    },
    initData() {
      this.getDesiRankList();
      this.getDesiTypeList();
      setTimeout(() => {
        this.getDequsList();
      }, 200);
    },
    // 获取物料信息
    getDequsList() {
      get(dequAPI.getDequPterByDequId, { dequ_id: this.dequId })
        .then(res => {
          if (res.data.code === 0) {
            this.loading = false;
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(() => {});
    },
    // 获取稿件等级
    getDesiRankList() {
      get(optnAPI.getOptnByPermId, { perm_id: 10002 })
        .then(res => {
          if (res.data.code === 0) {
            this.desiRankList = res.data.data.form.optn_cntt_list;
          }
        })
        .catch(() => {});
    },
    // 获取文档类型
    getDesiTypeList() {
      get(optnAPI.getOptnByPermId, { perm_id: 10001 })
        .then(res => {
          if (res.data.code === 0) {
            this.desiTypeList = res.data.data.form.optn_cntt_list;
          }
        })
        .catch(() => {});
    },

    // 文档类型转换
    formatType(val) {
      this.desiTypeList.forEach(item => {
        if (item.id === Number(val)) {
          return item.param1;
        }
      });
    },
    // 文档等级转换
    formatRank(val) {
      this.desiRankList.forEach(item => {
        if (item.id === Number(val)) {
          return item.param1;
        }
      });
    },
    // 清空
    clear() {
      this.$refs.multiTable.clearSelection();
    },
    // 指定一个key标识这一行的数据
    getRowKey(row) {
      return row.dequ_id;
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 取消选择
    cancel() {
      this.clear();
      this.$emit('cancelFalse', false);
    },
    // 确认选择
    confirmIn() {
      if (this.multiSelection.length > 0) {
        this.addDocuPter();
        this.cancel();
      } else {
        this.$message({
          message: '至少选择一条信息',
          type: 'warning'
        });
      }
    },
    // 上传调用接口
    addDocuPter() {
      let params = {};
      const ids = [];
      this.multiSelection.map(item => {
        ids.push(item.docu_id);
      });
      params.docu_id_list = ids;
      params.perm_id = this.propsForm.perm_id;
      params.form_id = this.propsForm.form_id;
      post(docuAPI.addDocuPter, params)
        .then(res => {
          let mg = res.data.msg;
          if (res.data.code === 0) {
            bus.$emit('refreshRequDocuList');
            let tp = 'success';
            this.$message({ message: mg, type: tp });
          } else {
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    clickView(row) {
      this.imageDialogVisible = true;
      this.showImgUrl = this.helper.megPath(row.docu_url);
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
.vd_mrt {
  margin-top: 20px;
}
.vd_mar15 {
  margin: 15px 0;
}
.vd_col_font {
  font-size: 16px;
}
</style>
